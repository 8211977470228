
import ApiService from "@/core/services/ApiService"
import { defineComponent, onMounted, ref, watch } from "vue"
import { Edit } from "@element-plus/icons-vue"
import { useRoute, useRouter } from "vue-router"
import { DateFormatTypes, dateForm } from "@/core/helpers/dateformat"
import TimePeriodComponent from "@/components/education/definitions/time-period/time-period.vue"
import { TimePeriod } from "@/core/models/TimePeriod"
import { SelectModel } from "@/core/models/SelectModel"
import swalConfirm from "@/core/helpers/swal-confirm"

export default defineComponent({
  name: "time-period-list",
  components: { TimePeriodComponent },
  props: ["add"],
  setup(props) {
    const route = useRoute()
    const router = useRouter()

    const isTableDataLoading = ref(false)
    const isBusinessSelectLoading = ref(false)

    const newTimePeriod = ref(false)

    const businessList = ref<SelectModel[]>([])
    const timePeriodList = ref<TimePeriod[]>([])
    const activeBusinessId = ref<string>()

    const activeTimePeriodId = ref<string | undefined>()

    watch(
      () => route.params.time_period_id,
      newValue => {
        activeTimePeriodId.value = newValue as string
      }
    )

    watch(
      () => props.add,
      newValue => {
        newTimePeriod.value = true == newValue
      }
    )

    onMounted(async () => {
      activeTimePeriodId.value = route.params.time_period_id as string
      newTimePeriod.value = props.add
      await getBusinessList()
    })

    async function getBusinessList() {
      isBusinessSelectLoading.value = true
      const { data } = await ApiService.get("business")
      businessList.value = data.filter(x => x.isActive).map(x => ({ id: x.id, name: x.title }))
      isBusinessSelectLoading.value = false
      if (businessList.value.length == 1) {
        activeBusinessId.value = businessList.value[0].id
        await getTimePeriodList(activeBusinessId.value)
      }
    }

    async function getTimePeriodList(businessId) {
      if (!businessId) return
      isTableDataLoading.value = true
      const { data } = await ApiService.get("time-period/business/" + businessId)
      timePeriodList.value = data
      isTableDataLoading.value = false
    }

    async function removeTimePeriod(timePeriodId) {
      const confirmResult = await swalConfirm()
      if (confirmResult.isConfirmed) {
        await ApiService.get("time-period/remove/" + timePeriodId)
        await getTimePeriodList(activeBusinessId.value)
      }
    }

    function handleTimePeriodDrawerClose() {
      router.push({ name: "time-period-list" })
    }

    return {
      dateForm,
      DateFormatTypes,
      isTableDataLoading,
      timePeriodList,
      businessList,
      activeBusinessId,
      Edit,
      removeTimePeriod,
      getTimePeriodList,
      activeTimePeriodId,
      newTimePeriod,
      handleTimePeriodDrawerClose,
      isBusinessSelectLoading,
    }
  },
})
