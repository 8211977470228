
import { SelectModel, SelectModelInt } from "@/core/models/SelectModel"
import { TimePeriod } from "@/core/models/TimePeriod"
import ApiService from "@/core/services/ApiService"

import { ElForm } from "element-plus"
import { computed, defineComponent, onMounted, ref, toRefs, watch } from "vue"
import { useRouter } from "vue-router"
import { getRule, RuleTypes } from "@/core/helpers/rules"
import { InternalRuleItem, Value } from "async-validator"

export default defineComponent({
  name: "time-period-component",
  components: {},
  props: ["add", "id", "closed"],
  emits: ["closed", "getlist"],
  setup(props, { emit }) {
    const router = useRouter()
    const { add, id } = toRefs(props)

    const timePeriodData = ref<TimePeriod>({} as TimePeriod)

    type FormInstance = InstanceType<typeof ElForm>

    const ruleFormRef = ref<FormInstance>()

    const businessList = ref<SelectModel[]>([])

    const dayList = ref<SelectModelInt[]>([
      {
        name: "Pazar",
        id: 0,
      },
      {
        name: "Pazartesi",
        id: 1,
      },
      {
        name: "Salı",
        id: 2,
      },
      {
        name: "Çarşamba",
        id: 3,
      },
      {
        name: "Perşembe",
        id: 4,
      },
      {
        name: "Cuma",
        id: 5,
      },
      {
        name: "Cumartesi",
        id: 6,
      },
    ])

    const validateStartTime = (
      rule: InternalRuleItem,
      value: Value,
      callback: (error?: string | Error) => void
    ) => {
      if (value && timePeriodData.value.endTime && value > timePeriodData.value.endTime)
        callback(new Error("Başlangıç zamanı bitiş zamanından önce olmalıdır"))
      else callback()
    }

    const validateEndTime = (
      rule: InternalRuleItem,
      value: Value,
      callback: (error?: string | Error) => void
    ) => {
      if (value && timePeriodData.value.startTime && value < timePeriodData.value.startTime)
        callback(new Error("Bitiş zamanı başlangıç zamanından sonra olmalıdır"))
      else callback()
    }

    const rules = ref({
      businessId: getRule(RuleTypes.SELECT, "İşletme"),
      startTime: getRule(RuleTypes.TIME, "Başlangıç", [
        {
          validator: validateStartTime,
          trigger: "blur",
        },
      ]),
      endTime: getRule(RuleTypes.TIME, "Bitiş", [
        {
          validator: validateEndTime,
          trigger: "blur",
        },
      ]),
      name: getRule(RuleTypes.TEXT50, "Ad"),
      code: getRule(RuleTypes.TEXT50, "Kod"),
      days: getRule(RuleTypes.MULTIPLE_SELECT, "Gün"),
    })

    const getTimePeriod = async id => {
      const { data } = await ApiService.get("time-period/" + id)
      timePeriodData.value = data
      timePeriodData.value.endTime = new Date(data.endTime)
      timePeriodData.value.startTime = new Date(data.startTime)
    }

    watch(
      () => props.id,
      newValue => {
        if (newValue) {
          ruleFormRef.value?.resetFields()
          getTimePeriod(newValue)
        }
      }
    )

    const showDrawer = computed(() => props.id != undefined || props.add == true)

    watch(
      () => props.add,
      newValue => {
        if (newValue) {
          ruleFormRef.value?.resetFields()
          timePeriodData.value = {} as TimePeriod
        }
      }
    )

    async function addTimePeriod() {
      const data = {
        ...timePeriodData.value,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        endTime: "01.01.1990 " + timePeriodData.value.endTime!.toLocaleTimeString(),
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        startTime: "01.01.1990 " + timePeriodData.value.startTime!.toLocaleTimeString(),
      }

      await ApiService.post("time-period/add", data)
      emit("getlist")
      router.push({ name: "time-period-list" })
    }
    async function updateTimePeriod() {
      const data = {
        ...timePeriodData.value,
        id: props.id,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        endTime: "01.01.1990 " + timePeriodData.value.endTime!.toLocaleTimeString(),
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        startTime: "01.01.1990 " + timePeriodData.value.startTime!.toLocaleTimeString(),
      }
      await ApiService.post("time-period/edit", data)
      emit("getlist")
      router.push({ name: "time-period-list" })
    }

    function addOrUpdate(formEl) {
      if (!formEl) return
      formEl.validate(valid => {
        if (valid) {
          if (props.add) {
            addTimePeriod()
          } else {
            updateTimePeriod()
          }
        }
      })
    }
    onMounted(async () => {
      await getBusinessList()
    })

    watch(showDrawer, async newValue => {
      if (newValue) {
        if (businessList.value.length == 1) {
          timePeriodData.value.businessId = businessList.value[0].id
        }
      }
    })

    async function getBusinessList() {
      const { data } = await ApiService.get("business")
      businessList.value = data.filter(x => x.isActive).map(x => ({ name: x.title, id: x.id }))
    }

    return {
      showDrawer,
      newTimePeriod: add,
      activeTimePeriodId: id,
      handleClose: props.closed,
      timePeriodData,
      addOrUpdate,
      ruleFormRef,
      businessList,
      dayList,
      rules,
    }
  },
})
